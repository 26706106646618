import { canUserAccess, getClient, isLoggedIn } from '@parkours/common';
import { PageNotFound } from '@parkours/ui';
import { createRouter, createWebHistory } from 'vue-router';
import { CurrentUserDocument, type Maybe, type User } from '@/generated/graphql';

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/confirm/:token',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "reset-confirm" */ '../views/Confirm.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },

  {
    path: '/file/:id',
    name: 'file',
    component: () => import(/* webpackChunkName: "file" */ '../views/File.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  let currentUser: Maybe<User> = null;

  if (isLoggedIn()) {
    try {
      const { data } = await getClient().query({
        fetchPolicy: 'network-only',
        query: CurrentUserDocument
      });
      currentUser = data.currentUser;
    } catch (e) {
      currentUser = null;
    }
  }
  const canAccess = canUserAccess(currentUser, to, from);
  if (!canAccess) {
    return '/';
  }
  return true;
});

export default router;
