import { getClient } from '@parkours/common';
import '@parkours/ui/dist/style.css';

import { DefaultApolloClient } from '@vue/apollo-composable';
import { createApp } from 'vue';
import App from './App.vue';
import './assets/index.css';
import router from './router';
import { DefaultRouter } from '@parkours/ui';
import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://696246684e0aaebe8c04b218d3430551@o4508002476425216.ingest.de.sentry.io/4508002747023440',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.APP_GTM_ID) {
  app.use(createGtm({ id: import.meta.env.APP_GTM_ID }));
}

app
  .use(router)
  .provide(DefaultApolloClient, getClient())
  .provide(DefaultRouter, router)
  .mount('#app');
